import React, { Component } from 'react'
import Selector from './Selector'
import AboutSection from './AboutSection'
import BeforeAfter from './BeforeAfter'

export default class Decking extends Component {
  render() {

    const about = (
      <p className="content">
        Millboard creates its boards from wood-free polyurethane combined with mineral stone, one of the most stable resin materials ensuring it won’t warp or rot. 
        It's highly-durable with exceptional anti-slip properties even when wet.
        Millboard decking requires little maintenance other than a simple wipe clean.
        All Millboard materials come with a 25-year guarantee, which can be registered 90-days after installation at <a href="https://www.millboard.co.uk/warranty-registration/" target="_blank" rel="noopener noreferrer">Millboard</a>.
        <br/><br/>
        To find out more about Millboard Decking, go to <a href="www.millboard.co.uk" target="_blank" rel="noopener noreferrer">www.millboard.co.uk</a>
      </p>
    )

    return (
      <div>
        <section className="decking-header">
          <div className="overlay py-5">
            <h1 className="title">Decking</h1>
          </div>
          <div className="background-image"></div>
        </section>
        <AboutSection 
          title="WHY MILLBOARD?"
          subtitle="We always recommend the highest quality products for our clients."
          content={about}
          image="https://millboard.co.uk/build/images/logo.svg"
        />
        <Selector />
        <section className="before-after bg-white">
          <h1 className="title">BEFORE &amp; AFTER</h1>
          <p className="subtitle">We love to see our work pay off and our clients happy. <br/> Here are some before and afters to show our work.</p>
          <div className="divider"></div>
          <BeforeAfter 
            before={require('../../Assets/images/before_1.jpg')} 
            after={require('../../Assets/images/after_1.jpg')}
            />
          <BeforeAfter 
            before={require('../../Assets/images/before_2.jpg')} 
            after={require('../../Assets/images/after_2.jpg')}
            />
        </section>
      </div>
    )
  }
}
