import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Swish extends Component {
  render() {
    return (
      <div className="container text-center my-5 swish">
        <div className="d-flex flex-md-row flex-column align-items-center justify-content-between">        
          <div className="col-lg-6 text-center mb-md-0 mb-4">
            <img className="img-fluid" src="https://www.swishbp.co.uk/themes/swish_theme_2013/images/swish-logo.png" alt="alternative" />
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div className="text-container text-md-left text-center pb-md-0 pb-3">
              <h2 className="text-primary mb-4">
                <strong>We Install Swish Fascias and Guttering</strong>
              </h2>
              <ul className="list-unstyled li-space-lg">
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>Weather-resistant
                </li>
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>No Discolouration
                </li>
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>Environmentally Sound
                </li>
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>20-Year Guarantee
                </li>
              </ul>
              <a className="btn btn-primary text-white px-4" href="/fascias">More Details
              </a>
            </div>
          </div>
        </div>
      </div>
      )
    }
  }
  