import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class BeforeAfter extends Component {
  render() {
    return (
      <>
        <div className="d-flex flex-md-row flex-column align-items-center justify-content-between">  
          <img className="img-fluid" src={this.props.before} alt="alternative" />
          <img className="img-fluid" src={this.props.after} alt="alternative" />
        </div>
      </>
    )
  }
}
