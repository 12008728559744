import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Footer extends Component {
  render() {

    let d = new Date();

    return (
    <footer class="footer">
      <div class="container">
        <div class="d-flex flex-md-row flex-column justify-content-between">
          <div class="d-flex flex-column justify-content-start align-items-center w-auto">
            <img src={require('../../Assets/images/deckplas_whitelogo.png')} width="100px" alt="Deckplas"/>
            <small class="d-block mb-3 text-white">© {d.getFullYear()}</small>
            <ul class="list-unstyled text-small">
              <li>
                <a class="text-white" href="mailto:info@deckplas.com">
                  <FontAwesomeIcon icon={['fas', 'envelope']} height="20px" className="mr-2"/>
                  <small>info@deckplas.com</small>
                </a>
              </li>
              <li>
              <a class="text-white" href="tel:07971549292">
                <FontAwesomeIcon icon={['fas', 'phone']} height="20px" className="mr-2"/>
                <small>07971 549292</small>
              </a>
              <br />
              <a class="text-white" href="tel:07380801370">
                <FontAwesomeIcon icon={['fas', 'phone']} height="20px" className="mr-2"/>
                <small>07380 801370</small>
              </a>
            </li>
            </ul>
          </div>
          <div>
            <h5 class="text-white">Features</h5>
            <ul class="list-unstyled text-small">
              <li><a class="text-white" href="/decking">Decking</a></li>
              <li><a class="text-white" href="/fascias">Fascias &amp; Guttering</a></li>
              <li><a class="text-white" href="/our-work">Our Work</a></li>
              <li><a class="text-white" href="/contact">Contact</a></li>
            </ul>
          </div>
          <div>
            <h5 class="text-white">Registered Address</h5>
            <ul class="list-unstyled text-small">
              <li><p class="text-white m-0">St. Fagans,</p></li>
              <li><p class="text-white m-0">Cardiff,</p></li>
              <li><p class="text-white m-0">South Wales,</p></li>
              <li><p class="text-white m-0">CF5</p></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    )
  }
}
