import React, { Component } from 'react';
export default class Swish extends Component {
  render() {
    return (
      <section className="about-us">
        <div className="overlay py-5">
          <h1 className="title">Our Story</h1>
          <p className="subtitle">The Baldwin Brothers</p>
          <div className="divider"></div>
          <p className="content">Deck Plas, originally known as ‘Baldwin &amp; Sons’ is a family-run business that was founded in Cardiff by our late-father Leonard Baldwin over 35 years ago. As brothers, we have continued to expand the company and our goal is to offer high-quality Decking and Fascia &amp; Guttering installations in and around South Wales.</p>
          <a className="btn btn-primary text-dark px-4" href="/contact">Enquire Today</a>
        </div>
        <div className="background-image"></div>
      </section>
      )
    }
  }
  