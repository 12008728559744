import React, { Component } from 'react'

export default class Gallery extends Component {
  render() {

    const images = [
      require('../../Assets/images/portfolio_1.jpeg'),
      require('../../Assets/images/portfolio_2.jpeg'),
      require('../../Assets/images/portfolio_3.jpeg'),
      require('../../Assets/images/portfolio_4.jpeg'),
      require('../../Assets/images/portfolio_5.jpeg'),
      require('../../Assets/images/portfolio_6.jpeg'),
      require('../../Assets/images/portfolio_7.jpeg'),
      require('../../Assets/images/portfolio_8.jpeg'),
      require('../../Assets/images/portfolio_9.jpeg'),
    ]

    const options = images.map((element, key) => {
      return (
        <div className="image">
          <img src={element}/>
        </div>
      )
    })

    return (
      <div className="container-fluid gallery-container">
        <h1 className="title">OUR PREVIOUS WORK</h1>
        <p className="subtitle">Here is some of our previous work.</p>
        <div className="divider"></div>
        <div className="gallery">
          {options}
        </div>
      </div>
    )
  }
}
