exports.dropNavigation = function () {
  var nav = document.getElementById("main-navigation");
  var bar = document.getElementById("bar-container");

  if (nav.className === "nav") {
    nav.className += " show";
    bar.className += " active";
  }
  else {
    nav.className = "nav";
    bar.className = "bar-container";
  }
}