import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Swish extends Component {
  render() {
    return (
      <div className="container text-center my-5 millboard">
        <div className="d-flex flex-md-row flex-column-reverse align-items-center justify-content-between">  
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div className="text-container text-md-left text-center pb-md-0 pb-3">
              <h2 className="text-primary mb-4">
                <strong>We Install Millboard Decking</strong>
              </h2>
              <ul className="list-unstyled li-space-lg">
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>Anti-Slip
                </li>
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>Low Maintenance
                </li>
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>Stain Resistant
                </li>
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>Rot &amp; Warp Resistant
                </li>
                <li className="point my-2">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="mr-2 text-primary"/>25-Year Guarantee On Materials
                </li>
              </ul>
              <a className="btn btn-primary text-white px-4" href="/decking">More Details
              </a>
            </div>
          </div>      
          <div className="col-lg-6 mb-md-0 mb-4">
            <img className="img-fluid" src="https://millboard.co.uk/build/images/logo.svg" alt="alternative" />
          </div>
        </div>
      </div>
    )
  }
}