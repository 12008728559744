import React, { Component } from 'react';
import { connect } from 'react-redux';
import Recaptcha from 'react-google-invisible-recaptcha';

// Import actions
import { contactForm } from '../../Actions/emails';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      number: "",
      message: "",
      confirmed: false,
      acceptCheck: "",
    }
  }
  
  onSubmit = event => {
    event.preventDefault();

    if (this.state.confirmed === true) {
      const submission = {
        email : this.state.email,
        name : this.state.name,
        number : this.state.number,
        message : this.state.message
      }

      this.props.contactForm(submission);

      this.setState({
        acceptCheck: "",
        email: "",
        name: "",
        number: "",
        message: "",
        confirmed: false
      })
    }
    else {
      this.setState({
        acceptCheck: "Please confirm you are happy to be contacted by Deck Plas."
      })
    }
  }

  onChecked = event => {
    this.setState({ [event.target.name]: event.target.checked });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div className="w-100 py-4 bg-dark d-flex justify-content-center align-items-center">
        <div className="contact-container rounded d-flex flex-column justify-content-center align-items-center p-5 my-md-5">
          <h2 className="text-white">CONTACT US</h2>
          <p className="text-white text-center">
            We are based in Cardiff and we work mainly in and around South Wales. 
            We are willing to travel further so please contact us and we will be happy to discuss if we can accommodate you.
          </p>
          <p className="text-white text-center">
            Alternatively, you can call us at &nbsp;
            <a class="text-white" href="tel:07380801370">
              07380 801370
            </a>
            &nbsp; or &nbsp;
            <a class="text-white" href="tel:07971549292">
              07971 549292
            </a>
          </p>
          <form className="d-flex flex-column justify-content-center align-items-center" onSubmit={this.onSubmit}>
            <Recaptcha
              ref={ ref => this.recaptcha = ref }
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              onResolved={ () => console.log( 'Captcha Success.' ) } />
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              className="w-100 mt-4 px-3"
              value={this.state.email}
              onChange={this.onChange}
              autoComplete="off"
              required/>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              className="w-100 mt-4 px-3"
              value={this.state.name}
              onChange={this.onChange}
              autoComplete="off"
              required/>
            <input
              type="text"
              name="number"
              placeholder="Contact Number (optional)"
              className="w-100 mt-4 px-3"
              value={this.state.number}
              onChange={this.onChange}
              autoComplete="off"/>
            <textarea
              name="message"
              rows={6}
              placeholder="Please provide as much information as possible for your enquiry"
              className="w-100 mt-4 px-3"
              value={this.state.message}
              onChange={this.onChange}
              autoComplete="off"
              required/>
            <div className="d-flex align-items-center">
              <input type="checkbox" className="mr-2" id="tandc" required="" name="confirmed" checked={this.state.confirmed} onChange={this.onChecked}/>
              <small className="my-2 text-white">I agree to be contacted by Deck Plas using the above information.</small>
            </div>
            <div className="d-flex align-items-center w-75">
              <button className="btn btn-lg btn-block btn-white rounded-pill" type="submit" name="subscribe">Submit!</button>
            </div>
          </form>
          <small className="text-danger">{this.state.acceptCheck}</small>
          <h5 className="text-success pt-2">{this.props.emails.submissionSuccess === true ? "Thanks for sending us a message. We aim to get back to you within 5 business days." : ""}</h5>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  emails: state.emails,
  errors: state.errors
});

export default connect(mapStateToProps, { contactForm })(ContactForm)
