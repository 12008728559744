import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

// Import Styling
import './Assets/sass/vendor/main.scss';

// Import FontAwesome
import { library }  from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import ReactGA from 'react-ga';

//setAuthToken 
import Home from './Components/Home/Home';
import Navigation from './Components/Navigation/Navigation';
import Contact from './Components/Contact/Contact';
import Decking from './Components/Decking/Decking';
import Gallery from './Components/Gallery/Gallery';
import Fascia from './Components/Fascia/Fascia';
import Footer from './Components/Navigation/Footer';

let options = {
  autoConfig: true, 	// set pixel's autoConfig
  debug: false, 		// enable logs
};

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL, options);
 
ReactPixel.pageView();

const ANALYTICS_KEY = process.env.REACT_APP_GOOOGLE_ANALYTICS_TRACKING_CODE;

ReactGA.initialize(ANALYTICS_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  render() {

    // Render Fontawesome
    library.add(fas, fab, far);

    return (
      <BrowserRouter>
        <Navigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/decking" component={Decking} />
          <Route exact path="/our-work" component={Gallery} />
          <Route exact path="/fascias" component={Fascia} />
          <Redirect exact from="*" to="/" />
        </Switch>
        <Footer />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(App);
