import {
  CONTACT_SUBMISSION,
} from '../Actions/types';

const initialState = {
  submissionSuccess: false,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CONTACT_SUBMISSION:
      return {
        ...state,
        submissionSuccess: true,
        loading: false,
      }
    default:
      return state;
  }
}