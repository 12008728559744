import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dropNavigation } from './dropdown';

export default class Navigation extends Component {
  render() {
    return (
    <nav>
      <div className="nav" id="main-navigation">
        <ul className="nav-left">
          <li className="item logo">
            <a className="navbar-brand ml-2 logo-font text-dark" href="/">
              <img src={require("../../Assets/images/deckplas.png")} height="35px" alt="Brand logo"/>
            </a>
          </li>
        </ul>
        <ul className="nav-right">
          <li className="item">
            <a className="link" href="/decking">Decking</a>
          </li>
          <li className="item">
            <a className="link" href="/fascias">Fascias &amp; Guttering</a>
          </li>
          <li className="item">
            <a className="link" href="/our-work">Our Work</a>
          </li>
          <li className="item">
            <a className="link" href="/contact">Contact Us</a>
          </li>
          <li className="item">
            <div className="divider"></div>
          </li>
          <li className="item icon-item">
            <a href="tel:07971549292">
              <FontAwesomeIcon icon={['fas', 'phone']} height="20px" className="mr-2"/>
              <small>07971 549292</small>
            </a>
          </li>
          <li className="item icon">
            <a className="icon" onClick={() => dropNavigation()}>
              <div className="bar-container" id="bar-container">
                <div className="bar top"></div>
                <div className="bar middle"></div>
                <div className="bar bottom"></div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    )
  }
}
