import React, { Component } from 'react'

export default class AboutSection extends Component {
  render() {
    return (
      <section className="about-decking">
        <h1 className="title">{this.props.title}</h1>
        <p className="subtitle">{this.props.subtitle}</p>
        <div className="divider"></div>
        {this.props.content}
        <img className="logo" src={this.props.image} alt="alternative"/>
        <a className="btn btn-primary text-dark px-4" href="/contact">Enquire Today</a>
      </section>
    )
  }
}
