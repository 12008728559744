import React, { Component } from 'react'

export default class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
    }
  }

  onClick = (key) => {
    this.setState({
      key
    })
  }

  render() {

    const images = [
      require('../../Assets/images/enhanced-grain-GoldenOak.png'),
      require('../../Assets/images/enhanced-grain-charred.jpg'),
      require('../../Assets/images/enhanced-grain-CopperedOak.png'),
      require('../../Assets/images/enhanced-grain-jarrah.png'),
      require('../../Assets/images/enhanced-grain-limedoak.png'),
      require('../../Assets/images/enhanced-grain-smokedoak.png'),
      require('../../Assets/images/lasta-grip-coppered.png'),
      require('../../Assets/images/lasta-grip-goldenoak.png'),
      require('../../Assets/images/weathered-oak-driftwood.png'),
      require('../../Assets/images/weathered-oak-embered.png'),
      require('../../Assets/images/weathered-oak-vintage.jpg'),
    ]

    const names = [
      'Enhanced Grain Golden Oak',
      'Enhanced Grain Charred',
      'Enhanced Grain Coppered Oak',
      'Enhanced Grain Jarrah',
      'Enhanced Grain Limedoak',
      'Enhanced Grain Smoked Oak',
      'Lasta Grip Coppered',
      'Lasta Grip Golden Oak',
      'Weathered Oak Driftwood',
      'Weathered Oak Embered',
      'Weathered Oak Vintage',
    ]

    const desc = [
      'A light blonde hardwood timbers, with a soft golden, sandy hue.',
      'Recreates the look of cedar that has been flame sealed.',
      'A beautiful rich brown with depth of colour.',
      'Warm earthy tones inspired by the real thing.',
      'A mix of limestone colours with subtle crème hues. ',
      'Timeless appeal and enduring beauty.',
      'Offers Millboards highest anti-slip rating without the rough feel of other gritted strip boards',
      'Offers Millboards highest anti-slip rating without the rough feel of other gritted strip boards',
      'The incomparable look of sea-washed timber.',
      'A dramatic statement in textured black.',
      'The raw beauty of a natural, weathered oak finish.',
    ]

    const options = images.map((element, key) => {
      return (
        <div class="option" onClick={() => this.onClick(key)}>
          <img src={element} />
        </div>
      )
    })

    return (
      <div className="container-fluid deck-selector" id="selector">
        <h1 className="title">DECKING SELECTOR</h1>
        <p className="subtitle">Choose from a wide variety of styles and colours to create the perfect look for you.</p>
        <div className="divider"></div>
        <img className="main-image mb-4" src={images[this.state.key]}/>
        <h3 className="text-white">{names[this.state.key]}</h3>
        <p class="content">{desc[this.state.key]}</p>
        <div class="options">
          {options}
        </div>
        <small className="text-white">Click decking to view</small>
      </div>
    )
  }
}
