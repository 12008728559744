import React, { Component } from 'react'

import AboutSection from '../Decking/AboutSection';

export default class Fascia extends Component {
  render() {

    const about = (
      <p className="content">
        Swish produces weather-resistant boards that will not warp, flake, crack or peel. 
        Swish uses Octyl Tin stabilised cellular PVC that will not suffer from 'pinking' discolouration.
        All our production is recycled either by ourselves or by our specialist waste disposal partner. 
        All our Swish WHITE PVC roofline and cladding products include a 20-Year Guarantee. 
        <br/><br/>
        To find out more about Swish, visit <a href="https://www.swishbp.co.uk/" target="_blank" rel="noopener noreferrer">www.swishbp.co.uk</a>
        <br/><br/>
      </p>
    )
    
    return (
      <div>
        <section className="decking-header">
          <div className="overlay py-5">
            <h1 className="title">Fascia &amp; Guttering</h1>
          </div>
          <div className="background-image"></div>
        </section>
        <AboutSection 
          title="WHY SWISH?"
          subtitle="We always recommend the highest quality products for our clients."
          content={about}
          image="https://www.swishbp.co.uk/themes/swish_theme_2013/images/swish-logo.png"
        />
      </div>
    )
  }
}
