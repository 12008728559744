import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Features extends Component {
  render() {
    return (
      <section className="features-section">
        <h1 className="title">WHAT WE OFFER</h1>
        <p className="subtitle">We offer many different services including installations and repairs.</p>
        <div className="divider"></div>
        <div className="features-list">
          <div className="feature">
            <h1><FontAwesomeIcon icon={['fas', 'tools']} className="text-primary"/></h1>
            <p>Alternative Decking</p>
            <small>High-quality, wood-free, Millboard alternative decking installations.</small>
          </div>
          <div className="feature">
            <h1><FontAwesomeIcon icon={['fas', 'tint']} className="text-primary"/></h1>
            <p>Fascias &amp; Guttering</p>
            <small>UPVC facias, soffits, guttering and cladding installations in white and colours.</small>
          </div>
          <div className="feature">
            <h1><FontAwesomeIcon icon={['fas', 'plus']} className="text-primary"/></h1>
            <p>Other Services</p>
            <small>Glass balustrade and outdoor sound system fitting. Fascia and guttering repairs and cleaning.</small>
          </div>
        </div>
      </section>
    )
  }
}
