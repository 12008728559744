import React, { Component } from 'react';

// Import Components
import Features from './Features';
import Swish from './Swish';
import Millboard from './Millboard';
import About from './About';
import Contact from '../Contact/Contact';

export default class Home extends Component {
  render() {
    return (
      <>
        <header className="masthead">
          <div className="overlay">
            <p className="subtitle text-uppercase">Registered Millboard Decking and Swish Fascia &amp; Guttering Installers based in SOUTH WALES</p>
            <h1 className="title py-3">LOVE YOUR LANDSCAPE</h1>
            <a className="btn btn-primary text-dark px-4" href="/contact">Contact us for a <strong>FREE QUOTE</strong> today</a>
          </div>
        </header>
        <Features />
        <About />
        <Millboard />
        <Swish />
        <Contact />
      </>
    )
  }
}
