import axios from "axios";

//Import Types from types.js
import {
  GET_ERRORS,
  CONTACT_SUBMISSION,
} from "./types";


export const contactForm = (submission) => dispatch => {
  axios
    .post("/api/contact/contactform", submission)
    .then(
      dispatch({
        type: CONTACT_SUBMISSION,
      })
    )
    .catch(errors => {
      dispatch({
        type: GET_ERRORS,
        payload: errors.response.data
      });
    });
}